import "./libs/0-loadCSS.js";
// import { support } from "./libs/0ff-supports.js";
import "./libs/0plugins.js";
import "./libs/0polyfills.js";
import "./libs/3flickity.pkgd.min.js";
import { ffgrid } from "./libs/ff-grid.js";
// import { tarteaucitron } from "../../libs/tarteaucitron.js";
import { local } from "brownies";

import "tarteaucitron";

(function ($) {
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Variables
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var w = window,
		d = document,
		$window = $(w),
		$html = $('html'),
		$body = $('body'),
		$header = $('#header').find('.nav'),
		$main = $('#main'),
		$footer = $('#footer'),
		windowH,
		windowW,
		scrollT,
		scrollUp = false,
		headerH,
		$adminBar = $('#wpadminbar'),
		has_adminBar = $body.hasClass('admin-bar'),
		adminBar_h = 0,
		serveur_test = d.location.hostname == "localhost",
		is_requeting = false,
		sauvScroll,
		$pageHeaderInner = $main.find('.page_headerInner'),
		$pageNav = $main.find('.pageNav'),
		$modale = $main.find(".modale_wrapper");


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Dimensions
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function update_dimensions() {
		windowH = $window.height();
		windowW = $window.width();
		headerH = $header.height();
		update_adminbar();
		update_scroll();
	}
	function update_scroll() {
		if ($window.scrollTop() < scrollT)
			scrollUp = true;
		else
			scrollUp = false;
		scrollT = $window.scrollTop();
		if (scrollT > headerH)
			$html.addClass('has-scrolled');
		else
			$html.removeClass('has-scrolled');
	}

	function update_adminbar() {
		// $adminBar = !$adminBar.length && has_adminBar ? $('#wpadminbar') : $adminBar;
		// adminBar_h = $adminBar.length && $adminBar.is(':visible') ? $adminBar.height() : 0;
		adminBar_h = parseInt($html.css("margin-top")) + parseInt($body.css("margin-top"));
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Helper functions
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Load js async
	function async(script, callback) {
		var s = document.createElement('script'),
			head = document.getElementsByTagName('head')[0];
		s.type = "text/javascript";
		s.async = true;
		s.defer = true;
		s.src = script;
		if (typeof callback == 'function') { s.addEventListener('load', function (e) { callback.call(); }, false); }
		head.appendChild(s);
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Scroll jusqu'a un element
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function scrollToEl($el, marge) {
		var offsetEl = 0;
		if (marge) {
			offsetEl -= marge;
		}
		if ($el.length) {
			offsetEl += $el.offset().top - adminBar_h;
			offsetEl += 2;
			var elMargin = parseInt($el.css('marginTop'));
			var elPadding = parseInt($el.css('paddingTop'));
			if (!elPadding && elMargin) {
				offsetEl -= elMargin;
			} else if (!elPadding) {
				elMargin = parseInt($el.prev().css('marginBottom'));
				elPadding = parseInt($el.prev().css('paddingBottom'));
				if (elPadding) {
					offsetEl -= elPadding;
				} else if (elMargin) {
					offsetEl -= elMargin;
				}
			}
		}
		if (offsetEl < scrollT) {
			offsetEl -= headerH;
		}
		scrollToOffset(offsetEl);
	}

	function scrollToOffset(offsetEl) {
		$('html, body').animate({
			scrollTop: offsetEl
		}, 300);
	}

	function scroller_init($cont) {
		$cont = $cont && $cont.length ? $cont : $body;
		var $directElToScroll = $cont.find('.js-scroll-to-me').first();
		if ($directElToScroll.length) scrollToEl($directElToScroll);

		$cont.find('a.js-scroller[href^="#"]').on('click', function (event) {
			var $this = $(this);
			var $target = $($this.attr('href'));
			if ($target.length && $this.attr('data-scroll-parent')) {
				$target = $target.closest($this.attr('data-scroll-parent'));
			}
			if ($target.length) {
				event.preventDefault();
				scrollToEl($target);
			}
		});
	}
	scroller_init();


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Supports
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var transformProp;
	(function () {
		var style = document.createElement('a').style;
		var prop;
		if (style[prop = 'webkitTransform'] !== undefined) {
			transformProp = prop;
		}
		if (style[prop = 'msTransform'] !== undefined) {
			transformProp = prop;
		}
		if (style[prop = 'transform'] !== undefined) {
			transformProp = prop;
		}
	}());


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Menu ouverture / fermeture
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var idMenuTimeOut = false;
	$body.find('.js-menu-toggle').on('click', function (event) {
		event.preventDefault();
		menu_toggle();
	});

	function menu_toggle() {
		if ($html.hasClass('is-nav-open')) {
			menu_close();
		} else {
			menu_open();
		}
	}

	function menu_open() {
		sauvScroll = scrollT;
		idMenuTimeOut = w.setTimeout(function () {
			$html.addClass('is-scrollBlocked');
		}, 100);
		$html.addClass('is-nav-open');
	}

	function menu_close() {
		if (!$html.hasClass('is-nav-open')) return;
		if (idMenuTimeOut) w.clearTimeout(idMenuTimeOut);
		$html.removeClass('is-scrollBlocked');
		w.scrollTo(0, sauvScroll);
		w.setTimeout(function () {
			$html.removeClass('is-nav-open');
		}, 50);
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Recherche ouverture / fermeture
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var idSearchTimeOut = false;
	$body.find('.js-search-toggle').on('click', function (event) {
		event.preventDefault();
		search_toggle();
	});

	function search_toggle() {
		if ($html.hasClass('is-search-open')) {
			search_close();
		} else {
			search_open();
		}
	}

	function search_open() {
		sauvScroll = scrollT;
		idSearchTimeOut = w.setTimeout(function () {
			$html.addClass('is-scrollBlocked');
			$body.find('.searchModale input').focus();
		}, 100);
		$html.addClass('is-search-open');
	}

	function search_close() {
		if (!$html.hasClass('is-search-open')) return;
		if (idSearchTimeOut) w.clearTimeout(idSearchTimeOut);
		$html.removeClass('is-scrollBlocked');
		w.scrollTo(0, sauvScroll);
		w.setTimeout(function () {
			$html.removeClass('is-search-open');
		}, 50);
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Menu qui se masque
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function auto_hide_header() {
		if (!scrollUp && scrollT > headerH)
			$html.addClass('is-nav-slided');
		else
			$html.removeClass('is-nav-slided');
	}
	auto_hide_header();


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Accordéons
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function acc_init($cont) {
		$cont = $cont && $cont.length ? $cont : $main;
		$cont.find('.acc:not(.js-acc)').addClass('js-acc');
		$cont.find('.js-acc:not(.is-init)').each(function (idx, el) {
			var $wrapper = $(el);
			var $head = $wrapper.children(':nth-last-child(2)');
			var $cont = $wrapper.children(':nth-child(2)');
			if (!$head.length || !$cont.length) return;
			$head.wrapInner('<button type="button"></button>');
			$wrapper.addClass('is-init').addClass('is-closed');
			$cont.hide();
			$head.on('click', 'button', function (event) {
				event.preventDefault();
				$cont.slideToggle(400);
				$wrapper.toggleClass('is-closed');
			});
		});
	}
	acc_init();

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Accordéons landing
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function acc_init($cont) {
		$cont = $cont && $cont.length ? $cont : $main;
		$cont.find('.acc_landing:not(.js-acc)').addClass('js-acc');
		$cont.find('.js-acc:not(.is-init)').each(function (idx, el) {
			var $wrapper = $(el);
			var $head = $wrapper.find('.acc_head');
			var $cont = $wrapper.find('.acc_content');
			if (!$head.length || !$cont.length) return;
			$head.wrapInner('<button type="button"></button>');
			$wrapper.addClass('is-init').addClass('is-closed');
			$cont.hide();
			$head.on('click', 'button', function (event) {
				event.preventDefault();
				$cont.slideToggle(400);
				$wrapper.toggleClass('is-closed');
			});
		});
	}
	acc_init();

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Modale landing
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

	function modale_scroll() {
		$modale.each(function () {
			var $modale = $(this);
			var $modale_box = $modale.find('.modale');
			if (scrollT + windowH - $modale_box.outerHeight() - parseInt($modale_box.css('margin-top')) - 20 > $modale.offset().top) {
				$modale_box.addClass("is-fixed");
			} else {
				$modale_box.removeClass("is-fixed");
			}
		});

	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Grille
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function grid_init() {
		$main.find('.js-grid').each(function (index, el) {
			ffgrid({ cont: el, item: '.social_i' });
		});
	}
	grid_init();


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Page nav
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var hasPageNavSticky = $pageNav.length && $pageNav.hasClass('is-sticky');
	var $lastElFooter = $main.find('.ctaLinks:last-child, .ctaText').first();
	function page_nav_update() {
		if (!$pageHeaderInner.length || !hasPageNavSticky) return;
		if ($pageHeaderInner.get(0).getBoundingClientRect().top < adminBar_h + headerH) {
			$html.addClass('is-page-nav-fixed');
		} else {
			$html.removeClass('is-page-nav-fixed');
		}
		if ($lastElFooter.length) {
			var footerOffset = $lastElFooter.get(0).getBoundingClientRect().top - parseInt($lastElFooter.css('margin-top')) - (parseInt($pageNav.css('top')) + $pageNav.outerHeight());
			if ($html.hasClass('is-page-nav-fixed') && footerOffset < 0) {
				$pageNav.css(transformProp, 'translateY(' + footerOffset + 'px' + ')');
			} else {
				$pageNav.removeAttr('style');
			}
		}
	}
	page_nav_update();


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Sliders Flickity
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function sliders_init($cont) {
		var $sliders = ($cont && $cont.length ? $cont : $main).find('.js-slider:not(.flickity-enabled)');
		if (!$sliders.length) return;

		$sliders.each(function (idx, el) {
			slider_set(el);
		});
	}
	sliders_init();

	function slider_set(slider) {
		var $slider = $(slider);
		$slider.addClass('is-init');

		if ($slider.children().length < 2) return;
		// $slider.on( 'resize.flickity', function() {
		//   console.log('resize');
		//   // flkty.slides.forEach(slide => slide.style.height = '');
		//   //
		//   // let heights = flkty.slides.map(slide => slide.offsetHeight),
		//   //     max = Math.math.apply(Math, heights);
		//   //
		//   // flkty.slides.forEach(slide => slide.style.height = max + 'px');
		// });

		$slider.flickity({
			// cellSelector: '.slider_i',
			setGallerySize: false,
			resize: false,
			cellAlign: 'center',
			adaptiveHeight: false,
			wrapAround: true,
			// contain         : true,
			percentPosition: true,
			// lazyLoad: 1, //<img src="placeholder.jpg" data-flickity-lazyload="full.jpg" />
			groupCells: false,
			// imagesLoaded    : false,
			// lazyLoad        : false,
			pageDots: true,
			prevNextButtons: false,
			freeScroll: false,
			// arrowShape      : 'M99.9 47.8H8.2l12.4-11.4-2.8-2.9L.1 50l17.7 16.5 2.8-3.1L8.2 51.8h91.7z',
			// autoPlay        : slider.hasAttribute('data-autoplay') ? 3000 : false,
			// pauseAutoPlayOnHover: false
		});
		$slider.on('dragStart.flickity', function () {
			$(this).addClass('is-dragging');
		});

		$slider.on('dragEnd.flickity', function () {
			$(this).removeClass('is-dragging');
		});

		if ($slider.hasClass('home')) {
			$window.on('mousewheel', function (event) {
				// console.log($slider.data('flickity'));
				if ($footer.get(0).getBoundingClientRect().top > windowH - 10 || scrollT > 0 || $slider.data('flickity').isAnimating) return;

				if (event.deltaY > 0) {
					//up
					$slider.flickity('previous');
				} else if (event.deltaY < 0) {
					//down
					$slider.flickity('next');
				}
			});
		}
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Actu ajax
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$main.find('.js-social-more').on('click', function (event) {
		event.preventDefault();
		if (is_requeting) return;
		is_requeting = true;
		var $link = $(this);
		$link.closest('.social').addClass('is-loading');
		$.ajax({
			url: url.ajax,
			type: 'GET',
			dataType: 'json',
			data: { action: 'load_actus', offset: $link.closest('.social').find('.social_i').length }
		})
			.done(function (data) {
				// if(data.success) {
				//   $header.find('[data-cart-nb]').attr('data-cart-nb', data.nb_products);
				//   $cart.find('.cart_cont').html(data.cart_content);
				// }
				if (data && data.content) {
					$link.closest('.social').find('.social_grid').append(data.content);
					grid_init();
				} else {
					$link.parent().remove();
				}
			})
			.fail(function () {
				// console.log("error");
			})
			.always(function () {
				// $cart.find('.cart_cont').removeClass('has-loader').removeClass('is-loading');
				is_requeting = false;
				$link.closest('.social').removeClass('is-loading');
			});

	});


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Filtres
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var globalFilters = false;
	$main.on('click', '.filters_i.is-multi .filters_label:not(:last-child)', function (event) {
		event.preventDefault();
		var $item = $(this).closest('.filters_i');
		if ($item.hasClass('is-open')) {
			// $item.removeClass('is-open').find('.filters_list').slideUp(250);
			$item.removeClass('is-open');
		} else {
			filters_close_all_panels();
			// $item.addClass('is-open').find('.filters_list').slideDown(250);
			$item.addClass('is-open');
		}
	});

	$main.on('click', '[data-filter-reset], [data-filter-name]', function (event) {
		filters_close_all_panels();
		filters_mobile_close();
		scrollToEl($main.find('.filters'));
	});

	function filters_close_all_panels() {
		// $main.find('.filters_i.is-open').removeClass('is-open').find('.filters_list').slideUp(250);
		$main.find('.filters_i.is-open').removeClass('is-open');
	}

	$main.on('click', '[data-filter-name]', function (event) {
		var $btn = $(this);
		var filterName = $btn.attr('data-filter-name');
		var filterValue = $btn.attr('data-filter');
		if ($btn.hasClass('is-active')) {
			$btn.removeClass('is-active');
		} else {
			$btn.closest('.filters_i').find('[data-filter-name]').removeClass('is-active');
			$main.find('[data-filter-reset]').removeClass('is-active');
			$btn.addClass('is-active');
		}
		filters_update();
	});
	filters_update();

	function filters_get_active() {
		var filters = {};
		$main.find('[data-filter-name].is-active').each(function (index, el) {
			filters[$(el).attr('data-filter-name')] = parseInt($(el).attr('data-filter'));
		});
		globalFilters = filters;
		return filters;
	}

	$main.on('click', '[data-filter-reset]', function (event) {
		$main.find('[data-filter-name].is-active').removeClass('is-active');
		$main.find('[data-filter-reset]').addClass('is-active');
		$main.find('[data-filter-select-name]').val('');
		filters_update();
	});

	function filters_update() {
		var filters = filters_get_active();
		$main.find('[data-filter-item]').show().filter(function (index, el) {
			var $el = $(el);
			var show = true;
			for (var f in filters) {
				show = filter_el_has_filter($el, f, filters[f]);
				if (!show) {
					break;
				}
			}
			return !show;
		}).hide();
		if (projectsMap) {
			maps_set_markers();
		}
		$main.find('.filters_i.is-multi .filters_label:not(:last-child)').removeClass('is-active');
		$main.find('.filters_i.is-multi [data-filter-name].is-active').closest('.filters_i').find('.filters_label').addClass('is-active');
		if (!$main.find('.filters_i.is-multi [data-filter-name].is-active, [data-filter-reset].is-active').length) {
			$main.find('[data-filter-reset]').addClass('is-active');
		}
	}

	function filter_el_has_filter($el, filter, val) {
		var attr = $el.attr('data-filter-' + filter);
		if (!attr || attr == '') {
			return false;
		}
		attr = attr.split(',').map(function (v) { return parseInt(v); });
		return attr.indexOf(val) > -1;
	}

	$main.on('click', '.filters_triggerMobile button', function (event) {
		event.preventDefault();
		$(this).closest('.filters').find('.filters_cont').slideToggle(250, function () {
			$(this).removeAttr('style');
			$(this).closest('.filters').toggleClass('is-mobile-open');
		});
	});

	function filters_mobile_close() {
		if ($main.find('.filters .filters_triggerMobile').is(':visible')) {
			$main.find('.filters .filters_cont').slideUp(250, function () {
				$(this).removeAttr('style');
				$(this).closest('.filters').removeClass('is-mobile-open');
			});
		}
	}

	$main.on('change', '.filters select', function (event) {
		if (this.value == '') {
			$main.find('.filters [data-filter-name="' + $(this).attr('data-filter-select-name') + '"].is-active').trigger('click');
		} else {
			$main.find('.filters [data-filter-name="' + $(this).attr('data-filter-select-name') + '"][data-filter="' + this.value + '"]').trigger('click');
		}
	});


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Gestion des maps
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $maps = $main.find('.gogoMap'),
		maps_styles = [
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#444444"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": "#f2f2f2"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 45
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": "#dbdbdb"
					},
					{
						"visibility": "on"
					}
				]
			}
		];

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Protection recaptcha
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function recaptcha_onload() {
		$('.formTalents').addClass('recaptcha_loaded');
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Icones
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	// Icone svg
	function maps_get_icon(color, size) {
		size = size ? size : 34;
		// https://stackoverflow.com/questions/24413766/how-to-use-svg-markers-in-google-maps-api-v3
		var template = [
			'<?xml version="1.0"?>',
			'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="' + size + 'px" height="' + size + 'px">',
			'<path fill="{{color}}" d="M14 13.398q1.203 0 2.051-0.848t0.848-2.051-0.848-2.051-2.051-0.848-2.051 0.848-0.848 2.051 0.848 2.051 2.051 0.848zM14 2.352q3.391 0 5.77 2.379t2.379 5.77q0 1.695-0.848 3.883t-2.051 4.102-2.379 3.582-1.996 2.652l-0.875 0.93q-0.328-0.383-0.875-1.012t-1.969-2.516-2.488-3.664-1.941-4.020-0.875-3.938q0-3.391 2.379-5.77t5.77-2.379z"></path>',
			'</svg>'
		].join('\n');
		var svg = template.replace('{{color}}', color);
		return maps_encode_icon(svg);
	}

	function maps_get_svg_round(color, size) {
		size = size ? size : 45;
		// https://stackoverflow.com/questions/24413766/how-to-use-svg-markers-in-google-maps-api-v3
		var template = [
			'<?xml version="1.0"?>',
			'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + size + ' ' + size + '" width="' + size + 'px" height="' + size + 'px">',
			'<circle fill="{{color}}" cx="' + (size * .5) + '" cy="' + (size * .5) + '" r="' + (size * .5) + '"/>',
			'</svg>'
		].join('\n');
		var svg = template.replace('{{color}}', color);
		return maps_encode_icon(svg);
	}

	// Icone encodée
	function maps_encode_icon(icon) {
		return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(icon);
	}

	// Icone google map
	function maps_pinSymbol(color, size) {
		size = size ? size : 34;
		return { url: maps_get_icon(color, size), scaledSize: new google.maps.Size(size, size) };
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Chargement de google map
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function maps_init() {
		if (!$maps.length) return;
		var gmapcallback = function () {
			maps_setup();
		}
		window.gmapcallback = gmapcallback;
		//async('//maps.googleapis.com/maps/api/js?key='+keys.map+'&callback=gmapcallback');
	}
	maps_init();


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Création des maps
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function maps_setup() {
		if (!$maps.length || !google || !google.maps) return;
		maps_clients_render($maps.get(0));
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Map clients
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var projectsMap = null;
	var maps_markerCluster = null;
	var maps_clientsPositions = null;
	var maps_projectsMarkers = null;
	var maps_infowindow = null;
	var urlHash = false;

	function maps_clients_render(gogomap) {
		var $gogomap = $(gogomap);

		// Réglages de base
		projectsMap = new google.maps.Map(gogomap, {
			zoom: 15,
			minZoom: 1,
			maxZoom: 16,
			center: new google.maps.LatLng(48.8821238, 2.3341638),
			gestureHandling: 'cooperative',
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: maps_styles,
			streetViewControl: false,
			overviewMapControl: false,
			mapTypeControl: false,
			panControl: false,
			zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL }
		});

		maps_infowindow = new google.maps.InfoWindow({ maxWidth: 250 });
		google.maps.event.addListener(projectsMap, 'click', function () {
			maps_infowindow.close();
		});

		$.getJSON(url.json_projects, function (data) {
			maps_clientsPositions = data.items;
			// Filtres de la forme : {'etat': 'terminee', 'projet_type': 2, 'departement': 5, 's': 'eglise'}
			maps_set_markers();
		});
	}

	function maps_get_markers() {
		// Création des markers
		var filters = globalFilters;
		var items = maps_clientsPositions ? maps_clientsPositions : {};
		var itemsFiltered = {};
		var arrayReturn = [];
		var itemIsValid = true;
		if (filters) {
			for (var itemID in items) {
				itemIsValid = true;
				// console.log(items[itemID]['s'].toLowerCase().removeAccents());
				if (items.hasOwnProperty(itemID)) {
					// console.log(items,filters);
					for (var filter in filters) {
						if (filters[filter]) {
							if (
								!items[itemID][filter]
								|| $.isArray(items[itemID][filter]) && $.inArray(filters[filter], items[itemID][filter]) == -1
								|| !$.isArray(items[itemID][filter]) && filters[filter] != items[itemID][filter]
							) {
								itemIsValid = false;
								break;
							}
						}
					}
					if (itemIsValid) itemsFiltered[itemID] = items[itemID];
				}
			}
		} else {
			itemsFiltered = items;
		}
		for (var itemID in itemsFiltered) {
			if (items.hasOwnProperty(itemID)) {
				var item = items[itemID];
				arrayReturn.push(new google.maps.Marker({
					id: item.id,
					title: item.title,
					position: { lat: item.lat, lng: item.lng },
					icon: maps_pinSymbol('#00B7EB'),
					optimized: false,
					othersDatas: {
						'title': typeof item.title != 'undefined' ? item.title : false,
						'secteur': typeof item.secteur != 'undefined' ? item.secteur : false,
						'structure': typeof item.structure != 'undefined' ? item.structure : false,
						'mission': typeof item.mission != 'undefined' ? item.mission : false,
						'competence': typeof item.competence != 'undefined' ? item.competence : false,
						'references': typeof item.references != 'undefined' ? item.references : false,
						'dont_ext': typeof item.dont_ext != 'undefined' && item.dont_ext
					}
				}));
			}
		}
		return arrayReturn;
	}

	function maps_set_markers() {
		if (!projectsMap) return;

		maps_projectsMarkers = maps_get_markers();

		if (!maps_projectsMarkers || !maps_projectsMarkers.length) {
			if (maps_markerCluster) {
				maps_markerCluster.clearMarkers();
			}
			// $main.find('.projectsMap').hide();
			return;
		}

		// if(!$main.find('.projectsMap').is(':visible')) {
		//   $main.find('.projectsMap').show();
		//   google.maps.event.trigger(projectsMap, 'resize');
		// }

		// Bounding
		var bounds = new google.maps.LatLngBounds();
		maps_projectsMarkers.forEach(function (marker, idx) {
			if (!marker.othersDatas.dont_ext)
				bounds.extend(marker.position);
		});
		//4 coordonnées qui représentent la france
		bounds.extend({ lat: 50.114769, lng: 2.445721 });
		bounds.extend({ lat: 47.141633, lng: 6.421431 });
		bounds.extend({ lat: 43.337669, lng: 1.367720 });
		bounds.extend({ lat: 47.957288, lng: -3.026811 });
		projectsMap.fitBounds(bounds);

		// Cluster
		// https://stackoverflow.com/questions/28178080/getting-google-map-markerclusterer-plus-icons-in-one-color
		var cluster_styles = [
			{
				width: 45,
				height: 45,
				url: maps_get_svg_round('#00B7EB'),
				textColor: 'white',
				textSize: 16
			}
		];
		if (maps_markerCluster) {
			maps_markerCluster.clearMarkers();
		}
		maps_markerCluster = new MarkerClusterer(projectsMap, maps_projectsMarkers, {
			maxZoom: 15,
			// gridSize: 40,
			styles: cluster_styles
		});

		// Clic sur markers
		maps_projectsMarkers.forEach(function (marker, idx) {
			google.maps.event.addListener(marker, 'click', maps_open_info);
		});

		//If single client
		if (!urlHash && window.location.hash) {
			var activeMarker = false;
			urlHash = window.location.hash.indexOf("#") != -1 ? window.location.hash.substring(1) : window.location.hash;
			urlHash = parseInt(urlHash.split('-')[0]);
			maps_projectsMarkers.forEach(function (marker, idx) {
				if (activeMarker === false && urlHash == parseInt(marker.id)) {
					activeMarker = idx;
				}
			});
			if (activeMarker !== false) {
				scrollToEl($main.find('.projectsMap_map'), $main.find('.projectsMap_map').outerHeight() * -.5 + windowH * .5);
				setTimeout(function () {
					projectsMap.setCenter(maps_projectsMarkers[activeMarker].position);
					projectsMap.setZoom(16);
					google.maps.event.trigger(maps_projectsMarkers[activeMarker], 'click');
				}, 1000);
			}
		}
	}

	function maps_open_info(e) {
		var marker = this;
		var datas = marker.othersDatas;
		var infoHtml = '<div class="gmInfo f1-14">';
		// if(datas.img)
		//   infoHtml += '<p class="gmInfo_img" style="background-image: url('+datas.img+')"></p>';
		// if(datas.type_name)
		//   infoHtml += '<p class="gmInfo_type txt-12 txt-b txt-uc txt-grey-5">' + datas.type_name + '</p>';
		infoHtml += datas.title ? '<h3 class="gmInfo_title">' + datas.title + '</h3>' : '';
		if (datas.references) {
			infoHtml += '<ul>';
			datas.references.forEach(function (ref, idx) {
				infoHtml += '<li><a href="' + ref.link + '">' + ref.title + '</a></li>';
			});
			infoHtml += '</ul>';
		}
		infoHtml += '</div>';
		maps_infowindow.setContent(infoHtml);
		maps_infowindow.open(projectsMap, marker);
		// https://stackoverflow.com/questions/18933367/gmaps-v3-infowindow-disable-the-close-x-button
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Formulaire
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	function form_init($cont) {
		$cont = $cont && $cont.length ? $cont : $main;
		$cont.find('.form_field input, .form_field textarea').on('blur', function (event) {
			if (this.value) $(this).addClass('has-content');
			else $(this).removeClass('has-content');
		}).trigger('blur');
		$cont.find('.form_field select').on('change', function (event) {
			if (this.value) $(this).addClass('has-content');
			else $(this).removeClass('has-content');
		}).trigger('change');
	}
	form_init();

	$main.find('.form select[multiple]').each(function (index, el) {
		$(el).select2({
			language: "fr",
			dropdownParent: $(el).closest('.form_field')
		});
	});


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Tags cloud
	// https://docs.anychart.com/Basic_Charts/Tag_Cloud
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var $tagsClouds = $main.find('.js-tags-cloud[data-datas]');
	function tags_clouds_init() {
		if (!$tagsClouds.length || !anychart) return;
		$tagsClouds.each(function (index, el) {
			tags_clouds_set(el);
		});
	}
	tags_clouds_init();

	function tags_clouds_set(el) {
		var jsonData = JSON.parse(el.getAttribute('data-datas'));
		if (!jsonData) return;
		var data = [];
		jsonData.forEach(function (el, idx) {
			data.push({ x: el.key, value: el.value });
		});

		chart = anychart.tagCloud(data);
		chart.normal().fill("#00B7EB");
		chart.hovered().fill("#00B7EB");
		chart.selected().fill("#00B7EB");
		chart.mode("spiral");
		chart.angles([0]);
		chart.tooltip(false);
		chart.fontFamily('GT America, Helvetica Neue, Helvetica, Arial, sans-serif');
		chart.textSpacing(5);
		chart.container(el);
		chart.draw();
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Vidéos
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var plyr_is_ready = false;
	var plyr_js_is_ready = false;
	var plyr_css_is_ready = false;
	var plyr_js_is_loading = false;
	var plyr_css_is_loading = false;

	function init_videos() {
		var $videosIframes = $body.find('.media-video.is-provider-youtube .media_wrapper, .media-video.is-provider-vimeo .media_wrapper, .js-plyr').not('.js-plyr-init');
		if (!$videosIframes.length) return;
		video_load_plyr();
	}
	init_videos();

	function video_load_plyr() {
		if (plyr_is_ready) {
			init_plyr_on_video();
			return;
		}

		if (!plyr_js_is_loading) {
			plyr_js_is_loading = true;
			async(url.theme + 'js/vendor/plyr.polyfilled.js', plyr_js_loaded);
		}
		if (!plyr_css_is_loading) {
			plyr_css_is_loading = true;
			loadCSS(url.theme + 'js/vendor/plyr.css', plyr_css_loaded);
		}
	}

	function plyr_js_loaded() {
		plyr_js_is_ready = true;
		check_plyr_is_ready();
	}

	function plyr_css_loaded() {
		plyr_css_is_ready = true;
		check_plyr_is_ready();
	}

	function check_plyr_is_ready() {
		if (!plyr_is_ready && plyr_js_is_ready && plyr_css_is_ready) {
			plyr_is_ready = true;
			init_plyr_on_video();
		}
		return plyr_is_ready;
	}

	function init_plyr_on_video() {
		$body.find('.media-video.is-provider-youtube .media_wrapper, .media-video.is-provider-vimeo .media_wrapper').not('.js-plyr-init').each(function (idx, el) {
			$(this).addClass('js-plyr');
			// $(this).removeClass('js-plyr').wrapInner('<div class="plyr__video-embed js-plyr"></div>');
		});
		$body.find('.js-plyr').addClass('plyr__video-embed');
		// $body.find('.js-plyr').each(function(idx, el) {
		//   if(!$(el).children('plyr__video-embed').length) {
		//     $(el).removeClass('js-plyr').wrapInner('<div class="plyr__video-embed js-plyr"></div>');
		//   }
		// });
		// https://github.com/sampotts/plyr
		var players = Plyr.setup('.js-plyr', {
			controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
		});
		$.each(players, function (idx, el) {
			if (el.elements.container) {
				var poster = $(el.elements.container).closest('[data-plyr-poster]:not([data-plyr-poster=""])').data('plyr-poster');
				if (poster) {
					el.poster = poster;
				}
			}
		});
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Annimation page
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	// cookies.expires = 10;
	// cookies.domain = url.cookie_domain;
	// cookies.path = url.cookie_path;
	// cookies.secure = false;
	// cookies.autojson = false;
	//
	// if(cookies('epiceum_prev_page')) {
	//   cookies({ epiceum_prev_page: null });
	// }
	//
	// setTimeout(function() {
	//   $html.removeClass('is-page-arriving');
	// },500);
	//
	// $body.on('click', 'a[href^="'+url.home+'"]:not(.js-scroller):not(.js-search-toggle):not(.js-social-more)', function(event) {
	//   event.preventDefault();
	//   cookies({ 'epiceum_prev_page': window.location.href });
	//   $html.removeClass('is-page-arriving').addClass('is-page-leaving');
	//   var urlLink = this.href;
	//   setTimeout(function() {
	//     window.location = urlLink;
	//   },320);
	// });


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Keys event
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$html.on("keydown", function (event) {
		// event.which (27: echap, etc.)
		if (event.which == 27) {
			menu_close();
		}
	});

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Modale pour landing
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	const $popup = $(".popup");

	const close_popup = () => {
		$body.removeClass("hasPopup");
		$popup.addClass("is-hidden");
		local.is_popup_hidden = true;
		// Syntaxe « 1_000 » : Voir https://www.javascripttutorial.net/es-next/javascript-numeric-separator/
		local.hidden_time = Date.now() / 1_000;
		$popup.attr("aria-hidden", true);
		$body.attr("aria-hidden", false);
	};
	if ($popup.length !== 0) {
		const isHidden = local.is_popup_hidden;
		const hiddenTime = local.hidden_time || 0;
		// Syntaxe « 1_000 » : Voir https://www.javascripttutorial.net/es-next/javascript-numeric-separator/
		const dateNow = Date.now() / 1_000;
		const twoDays = 172_800;
		if (!isHidden || dateNow - hiddenTime > twoDays) {
			$body.addClass("hasPopup");
			$popup.removeClass("is-hidden");
			$popup.attr("aria-hidden", false);
			$popup.trigger("focus");
			$body.attr("aria-hidden", true);
			local.is_popup_hidden = false;
			delete local.hidden_time;
		}
		$popup.find(".popup_close").on("click", close_popup);
		$popup.find(".popup_link").on("click", close_popup);
	}


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//On resize
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	$window.on('resize', function (event) {
		update_dimensions();
		grid_init();
	}).trigger('resize');


	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//On scroll
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	var ticking = false;
	$window.on('scroll', function (event) {
		update_scroll();
		requestTick();
		modale_scroll();
	}).trigger('scroll');

	function requestTick() {
		if (!ticking) {
			ticking = true;
			w.requestAnimationFrame(trigger_scroll);
		}
	}

	function trigger_scroll() {
		ticking = false;
		auto_hide_header();
		page_nav_update();
	}

	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//Tarteaucitron
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	//■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	tarteaucitron.init({
		"privacyUrl": "", /* Privacy policy url */

		"hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
		"cookieName": "tarteaucitronepiceum", /* Cookie name */

		"orientation": "bottom", /* Banner position (top - bottom) */

		"showAlertSmall": false, /* Show the small banner on bottom right */
		"cookieslist": false, /* Show the cookie list */

		"showIcon": false, /* Show cookie icon to manage cookies */
		"iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */

		"adblocker": false, /* Show a Warning if an adblocker is detected */

		"DenyAllCta": true, /* Show the deny all button */
		"AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
		"highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

		"handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

		"removeCredit": true, /* Remove credit link */
		"moreInfoLink": true, /* Show more info link */

		"useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
		"useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

		//"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

		"readmoreLink": "", /* Change the default readmore link */

		"mandatory": true, /* Show a message about mandatory cookies */
	});

	tarteaucitron.user.googlemapsKey = keys.map;
	tarteaucitron.user.mapscallback = 'gmapcallback';
	(tarteaucitron.job = tarteaucitron.job || []).push('googlemaps');
	(tarteaucitron.job = tarteaucitron.job || []).push('youtube');

	tarteaucitron.user.linkedininsighttag = '6892266';
	(tarteaucitron.job = tarteaucitron.job || []).push('linkedininsighttag');

	tarteaucitron.user.recaptchaapi = '6Ld7jQErAAAAAGV5eOhJ4pDStDqXd0AVWu1es9hq';
	tarteaucitron.user.recaptchaOnLoad = recaptcha_onload;
	(tarteaucitron.job = tarteaucitron.job || []).push('recaptcha');

})(jQuery);

